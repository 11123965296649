import { getCaptcha, registered, login, detect } from '../services'
const editorModel = {
  namespace: 'userInfo',
  state: {
  },
  effects: {
    *detect({ payload }:any, { call }:any) {
      const res = yield call(detect, payload)
      return res 
    },
    *getCaptcha({ payload }:any, { call }:any) {
      const res = yield call(getCaptcha, payload)
      return res
    },
    *registered({ payload }:any, { call }:any) {
      const res = yield call(registered, payload)
      return res || []
    },
    *login({ payload }:any, { call }:any) {
      const res = yield call(login, payload)
      return res || {}
    },
  },
  reducers: {

  },
  subscriptions: {
  },
}

export default editorModel
