import request from '@/utils/req'

export async function detect(data: any): Promise<any> {
  return request.get(`/vip/email/exist?n=${data.n}`)
}

export async function getCaptcha(data: any): Promise<any> {
  return request.post('/vip/email/valid', data)
}

export async function registered(data: any): Promise<any> {
  return request.post('/vip/register', data)
}

export async function login(data: any): Promise<any> {
  return request.post('/vip/login', data)
}
