import { Effect } from 'umi'
import { getList, getUserList, delUserScreen, copyScreen, postView } from '../services'

export interface templateState {
    isLoading: boolean
}

interface templateType {
    namespace: 'template'
    state: templateState
    reducers: {}
    effects: {
      getList: Effect
      getUserList: Effect
      delUserScreen: Effect
      copyScreen: Effect
      postView: Effect
    }
}

const template: templateType = {
  namespace: 'template',
  state: {
    isLoading: false,
  },
  reducers: {},
  effects: {
    *getList(_, { call }) {
      const res = yield call(getList)
      return res || []
    },
    *getUserList(_, { call }) {
      const res = yield call(getUserList)
      return res || []
    },
    *delUserScreen({ payload }, { call }) {
      const res = yield call(delUserScreen, payload)
      return res || {}
    },
    *copyScreen({ payload }, { call }) {
      const res = yield call(copyScreen, payload)
      return res || {}
    },
    *postView({ payload }, { call }) {
      const res = yield call(postView)
      return res || {}
    },
  },
}

export default template
