import requst from '@/utils/req'

// 获取精选模板的数据列表
export async function getList(): Promise<any> {
  return requst.get('/visible/tpls/free')
}

// 获取我的大屏数据列表
export async function getUserList(): Promise<any> {
  return requst.get('/vip/v6/get')
}

// 删除我的大屏某一项
export async function delUserScreen(data:any): Promise<any> {
  return requst.delete(`/vip/v6/del?tid=${data.tid}`)
}

// 复制我的大屏某一项
export async function copyScreen(data:any): Promise<any> {
  return requst.post(`/visible/v6/copy?tid=${data.tid}`)
}

// 添加页面访问埋点
export async function postView(): Promise<any> {
  return requst.get(`/vip/num`)
}
